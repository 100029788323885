<template>
  <section class="gardenManagementPage">
    <SearchForm class="dis-flex flex-x-start flex-y-start">
      <div class="dis-flex flex-x-start flex-y-start">
        <el-form
          ref="ref_searchForm"
          :inline="true"
          label-width="80px"
        >
        <el-form-item >
            <div style="width: 220px">
              <el-select
                v-model="detailForm.type"
                placeholder="笔记类型"
                @change="changeUnit"
                class="t-c"
              >
                <el-option
                  class="t-c"
                  v-for="(item, index) in unitList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </div>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="detailForm.activityId"
              placeholder="活动ID"
            />
          </el-form-item>
          <el-form-item>
            <el-input v-model.trim="detailForm.redBookId" placeholder="抖音/小红书/快手号" />
          </el-form-item>
          <el-form-item>
            <el-input v-model.trim="detailForm.wechatId" placeholder="微信号" />
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="dayTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="提交开始时间"
              end-placeholder="提交结束时间"
              format="yyyy-MM-dd"
              data-format="yyyy-MM-dd"
              @change="selectTime"
            >
            </el-date-picker>
          </el-form-item>
            <el-button type="primary" @click="querycshopmallKocNotesPage">搜 索</el-button>
          </el-form-item>
          <el-form-item class="m-left25">
            <el-button type="primary" @click="clearData">清 除</el-button>
          </el-form-item>
        </el-form>
      </div>
    </SearchForm>
    <SearchForm class="dis-flex flex-x-start flex-y-start">
      <div class="dis-flex flex-x-start flex-y-start">
        <el-form
          ref="ref_searchForm"
          :inline="true"
          label-width="80px"
        >
       
          <!-- <el-form-item >
            <el-date-picker
              v-model="dayTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="数据开始时间"
              end-placeholder="数据结束时间"
              format="yyyy-MM-dd"
              data-format="yyyy-MM-dd"
              @change="selectImportTime"
            >
            </el-date-picker>
          </el-form-item> -->

          <el-form-item>
            <el-date-picker
              v-model="importTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="数据开始时间"
              end-placeholder="数据结束时间"
              format="yyyy-MM-dd"
              data-format="yyyy-MM-dd"
              @change="selectImportTime"
            >
            </el-date-picker>
          </el-form-item>

            <el-form-item >

        
        <el-form-item>
                                              
            <el-button type="primary" @click="querycshopmallKocNotesExport">导出笔记</el-button>
          </el-form-item>

          </el-form-item>
          <el-button @click="add" type="primary">添加笔记</el-button>
        </el-form>
      </div>
    </SearchForm>

    <TableContainer title="">
      <el-table
        :data="detailData"
        stripe
        style="width: 100%"
        border
        :header-cell-style="tabHeader"
        :cell-style="{ textAlign: 'center' }"
      >
      <el-table-column prop="id" label="ID" min-width="30" />
        <el-table-column prop="avatarUrl" label="头像" min-width="60">
          <template slot-scope="scope">
            <div>
              <img :src="scope.row.avatarUrl" width="60" height="60" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="nickName" label="微信相关信息" min-width="80" >
          <template slot-scope="scope">
            <div class="t-l">
              <p>推荐人:{{scope.row.referrer}}</p>
              <div class="m-top4">
                <p>微信号</p>
              <p class="f-w">{{scope.row.wechatId}}</p>
              </div>
              <div class="m-top4">
                <p>微信昵称</p>
              <p class="f-w">{{scope.row.nickName}}</p>
              </div>
         
             
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="userintegral" label="用户积分" min-width="50" />
   
        <el-table-column prop="redBookName" :label="detailForm.type=='0'?'抖音昵称':detailForm.type=='1'?'小红书昵称':detailForm.type=='2'?'快手昵称':'小红书/抖音/快手昵称'" min-width="60" />
        <el-table-column prop="redBookId" :label="detailForm.type=='0'?'抖音ID号':detailForm.type=='1'?'小红书ID号':detailForm.type=='2'?'快手ID号':'小红书/抖音/快手ID号'" min-width="60" />
        <el-table-column prop="noteLink" label="笔记链接" min-width="120" />
        <el-table-column prop="noteCollectionImage" label="笔记图" min-width="60">
          <template slot-scope="scope" >
          <span v-for="(item2,index2) in scope.row.noteCollectionImage" >
            <el-popover
              placement="left"
              width="400"
              trigger="click">
               <img  :src="item2"  width="300px" height="400px" >
            <img  slot="reference" :src="item2" width="60px" height="60px" >
          </el-popover>

          </span>
        </template>

     
        </el-table-column>

        <el-table-column prop="dwnoteLink" label="第三方平台链接" min-width="50" />
  
        <el-table-column label="审核状态" min-width="120">
          <template slot-scope="scope">
            <p>{{scope.row.activityName}}</p>
            <div class="t-c m0 " style='width:80%;line-height:40px;'>
             <span :class="scope.row.isExamine==1?'':'col-red'"> {{ scope.row.examineName}}</span>  <span v-show="scope.row.isExamine==1">
              +{{ scope.row.integral}}分
            </span>
            </div>
           

            <div v-show="(scope.row.isExamine==1&&scope.row.remarks!==null)||(scope.row.isExamine==2&&scope.row.remarks!==null)">
             {{scope.row.isExamine==2?'驳回':scope.row.isExamine==1?'加分':''}}原因:{{ scope.row.remarks}}
            </div>
           
          </template>
        </el-table-column>
        <el-table-column prop="createDay" label="提交时间" min-width="100" />
        <el-table-column width="150" label="操作">
          <template slot-scope="scope">
            <el-button type="text" class="textOrange" @click="approveClick(scope.row)"
            v-show="scope.row.isExamine==0">审核</el-button
            >
            <el-button type="text" class="textOrange" @click="unapproveClick(scope.row)"
            v-show="scope.row.isExamine==0">驳回</el-button
            >
            <el-button type="text" class="textOrange" @click="failedClick(scope.row)"
            v-show="scope.row.isExamine==1">取消审核</el-button
            >
            <el-button type="text" class="textOrange" @click="delteClicks(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <GPagination
        v-show="detailTotal > 0"
        ref="ref_GPagination"
        :total="detailTotal"
        :page.sync="detailForm.page"
        :size.sync="detailForm.size"
        @pagination="querycshopmallKocNotesPage"
        :page-sizes="pageSizes"
      />
    </TableContainer>

   

    
        <!-- 审核s -->
        <GDialog
      :dialog.sync="approveDialog"
      @btnSure="approvesubmitThemeForm()"
      @btnClose="resetThemeForm()"
      width="30%"
    >
      <el-form ref="ref_themeForm" label-width="100px" @submit.native.prevent>
        <el-form-item label="活动名称" label-width="100px">

<div style="width: 220px">
  <p>{{approveRow.activityName}}</p>
</div>

</el-form-item>
        <el-form-item label="用户昵称" label-width="100px">

<div style="width: 220px">
  <p>{{approveRow.nickName}}</p>
</div>

</el-form-item>
        <el-form-item label="积分数值" label-width="100px">
          <div style="width: 220px">
            <el-input
              v-model.trim="integral"
              placeholder="请输入积分数值"
              type="number"
            />
          </div>
        </el-form-item>
        <el-form-item label="备注" label-width="100px">
          <div >
            <el-input  v-model.trim="remarks" type="textarea" maxlength="100" placeholder="请输入备注" />
          </div>
        </el-form-item>
  
      </el-form>
    </GDialog>
    <!-- 审核e -->

    <!-- 添加笔记s -->
        <GDialog
      :dialog.sync="addDialog"
      @btnSure="addsubmitThemeForm()"
      @btnClose="resetThemeForm()"
      width="40%"
    >
      <el-form ref="ref_themeForm" label-width="100px" @submit.native.prevent>
        <el-form-item :label="addParmas.activityId!==''?addRow.typeName+'活动名称':'活动名称'" label-width="110px">
            <div style="width: 300px">
              <el-select
                v-model="addParmas.activityId"
                placeholder="活动名称"
                @change="changeActivity"
                class="t-c"
                style="width: 300px"
              >
                <el-option
                  class="t-c"
                  v-for="(item, index) in activityList"
                  :key="index"
                  :label="item.activityName"
                  :value="item.id"
                />
              </el-select>
             
            </div>
          </el-form-item>
          <el-form-item label="会员ID" label-width="100px">
          <div style="width: 300px">
            <el-input
              v-model.trim="addParmas.userId"
              placeholder="请输入会员ID"
              type="number"
            />
          </div>
        </el-form-item>
        <el-form-item :label="addRow.type=='0'?'抖音昵称':addRow.type=='1'?'小红书昵称':addRow.type=='2'?'快手昵称':'平台昵称'" label-width="100px">
          <div style="width: 300px">
            <el-input
              v-model.trim="addParmas.redBookName"
              placeholder="请输入昵称"
              type="text"
            />
          </div>
        </el-form-item>
        <el-form-item :label="addRow.type=='0'?'抖音ID':addRow.type=='1'?'小红书ID':addRow.type=='2'?'快手ID':'平台ID'" label-width="100px">
          <div style="width: 300px">
            <el-input
              v-model.trim="addParmas.redBookId"
              placeholder="请输入平台ID"
              type="number"
            />
          </div>
        </el-form-item>
        <el-form-item label="微信号" label-width="100px">
          <div style="width: 300px">
            <el-input
              v-model.trim="addParmas.wechatId"
              placeholder="请输入微信号"
              type="text"
            />
          </div>
        </el-form-item>
        <el-form-item label="推荐人" label-width="100px">
          <div style="width: 300px">
            <el-input
              v-model.trim="addParmas.referrer"
              placeholder="请输入推荐人微信昵称及微信号"
              type="text"
            />
          </div>
        </el-form-item>
        <el-form-item label="作品链接" label-width="100px">
          <div >
            <el-input  v-model.trim="addParmas.noteLink" type="textarea" maxlength="100" placeholder="请输入作品链接" />
          </div>
        </el-form-item>

        <el-form-item label="播放量/互动量截图(750*750)" label-width="200px">
          <el-upload
                action="http://47.100.63.204:8421/manager/common/uploadFileXtg"
                list-type="picture-card"
                :on-preview="bannerhandlePictureCardPreview"
                :on-remove="bannerhandleRemove"
                :on-success="bannerhandleSuccess"
                :file-list.sync="addParmas.noteCollectionImage"
              
              >
              <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="bannerdialogVisible">
                <img width="100%" :src="bannerdialogImageUrl" alt="" />
              </el-dialog>
        </el-form-item>
  
      </el-form>
    </GDialog>
    <!-- 添加笔记e -->


    <!-- 导出s -->
    <GDialog
      :dialog.sync="scoreDialog"
      @btnSure="scoreSubmitThemeForm()"
      @btnClose="resetThemeForm()"
      width="20%"
      :showCloseBtn="true"
      :showFooter="false"
    >
      <el-form
        ref="ref_themeForm"
        @submit.native.prevent
        class="dis-flex flex-dir-column flex-x-center flex-y-center"
      >
        <el-form-item>
          <p>确认导出笔记管理数据?</p>
        </el-form-item>

        <download-excel class="export-excel-wrapper" :data="jsonData" :name="currentTime+'笔记管理.xlsx'">
				    	<el-button @click="down" type="primary" style="margin-bottom:30px">导出笔记</el-button>
				</download-excel>
      </el-form>
    </GDialog>
    <!-- 导出e -->


  </section>
  </section>
</template>

<script>
import { parseTime } from "@utils/filters";
import Tinymce from "./tinymce";
import {
  querycshopmallKocNotesPage,
  addcshopmallKocActivity,
  updatecshopmallKocActivity,
  updatecshopmallKocNotes,
  delcshopmallKocNotes,
  querycshopmallKocNotesExport,
  querycshopmallKocActivity,
  addcshopmallKocNotes
 
} from "@api/bx/api_contentManagement";
import uploadPics from "@/components/Upload/uploadPics.vue";
import uploadPicss from "@/components/Upload/uploadPicss.vue";

export default {
  components: {
    uploadPics,
    uploadPicss,
    Tinymce,
  },
  data() {
    return {
      scoreDialog: {
        title: "导出操作",
        visible: false,
      },
      approveDialog: {
        title: "审核",
        visible: false,
      },
      addDialog: {
        title: "添加笔记",
        visible: false,
      },
      searchDialog: {
        title: "活动",
        visible: false,
      },
      detailForm: {
        page: 1,
        size: 10,
        start: "",
        end: "",
        redBookId: "",
        wechatId: "",
        type: "",
        activityId:''
      },
      detailData: [],
      detailRow: {},
      detailTotal: 0,
      pageSizes: [10, 20, 30, 40, 50],
      unitList: [
        { name: "抖音", id: "0" },
        { name: "小红书", id: "1" },
        { name: "快手", id: "2" },
      ],
      dayTime: "",
      index: 0,
      approveRow:{},
      integral:'',
      remarks:'',
      importTime:'',
      importParmas:{
        start: "",
        end: "",
      },
      jsonData: [],
      importData:[],
      currentTime:'',
      activityList:[],
      addParmas:{
        activityId:'',
        noteCollectionImage:[],
        noteLink:'',
        redBookId:'',
        redBookName:'',
        referrer:'',
        userId:'',
        type:'',
        wechatId:''
      },
      addRow:{},
      bannerfileList: [],
      bannerdialogImageUrl: "",
      bannerdialogVisible: false,
    };
  },
  created() {
    this.currentTime = parseTime(new Date(), "{y}-{m}-{d}");
    // console.log(this.currentTime);
    this.querycshopmallKocNotesPage();
    this.querycshopmallKocActivity()
  },
  methods: {

    tabHeader() {
      return "backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;";
    },

    clearData() {
      this.detailForm.page = 1;
      this.detailForm.size = 10;
      this.detailForm.start = "";
      this.detailForm.end = "";
      this.detailForm.type = "0";
      this.detailForm.redBookId = "";
      this.detailForm.wechatId = "";
      this.detailForm.type = "";
      this.detailForm.activityId = "";
      this.dayTime = "";
      this.querycshopmallKocNotesPage()

    },
    down() {
      this.jsonData = [];
      this.$message.success("努力导出中");
      for (var i of this.importData) {
        this.jsonData.push({
          客户编号: i.id,
          微信昵称: i.nickName,
          微信号: i.wechatId,
          客户积分: i.userintegral,
          抖音小红书快手名称: i.redBookName,
          抖音小红书快手ID号: i.redBookId,
          所属活动: i.activityName,
          笔记链接: i.noteLink,
          提交时间: i.createDay,
          是否审核: i.examineName,
          备注: i.remarks,
        });
      }
      this.scoreDialog.visible = false;
    },
    //列表时间筛选
    selectImportTime(val) {
      console.log(val);
      if (val == null) {
        this.importTime = "";
        this.importParmas.start = "";
        this.importParmas.end = "";
      } else {
        this.importTime = [
          parseTime(val[0], "{y}-{m}-{d}"),
          parseTime(val[1], "{y}-{m}-{d}"),
        ];
        this.importParmas.start = this.importTime[0];
        this.importParmas.end = this.importTime[1];
      }
      this.querycshopmallKocNotesExport()
    },

    selectTime(val){
      if (val == null) {
        this.dayTime = "";
        this.detailForm.start = "";
        this.detailForm.end = "";
      } else {
        this.dayTime = [
          parseTime(val[0], "{y}-{m}-{d}"),
          parseTime(val[1], "{y}-{m}-{d}"),
        ];
        this.detailForm.start = this.dayTime[0];
        this.detailForm.end = this.dayTime[1];
      }
      this.querycshopmallKocNotesPage()
    
    },



    changeUnit(val) {
      this.detailForm.type = val;
    },

    changeActivity(val) {
      this.addParmas.activityId = val;
      this.activityList.map((item, index) => {
        if (this.addParmas.activityId == item.id) {
          this.addRow = item;
        }
      });
    },



  
    querycshopmallKocNotesPage() {
      querycshopmallKocNotesPage(this.detailForm).then((res) => {
        res.records.map((item, index) => {
          if(item.noteCollectionImage!==''||item.noteCollectionImage!==null){
         item.noteCollectionImage=item.noteCollectionImage.split(',')


          }
      });
        this.detailData = res.records;
        this.detailTotal = res.total;
        console.log(this.detailData)
      });
    },
    querycshopmallKocNotesExport() {
      // console.log("6666");
      querycshopmallKocNotesExport(this.importParmas).then((res) => {
        this.importData=res
        this.scoreDialog.visible = true;
      });

    },

    querycshopmallKocActivity() {
      querycshopmallKocActivity({}).then((res) => {
        this.activityList=res
       
      });
    },



    //通过审核
    approveClick(row) {
      this.approveRow=row
      this.approveDialog.visible=true
    },
    
    approvesubmitThemeForm(){
      if(this.integral==''){
        this.$alert("请输入积分数值", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if(this.remarks==''){
        this.$alert("请输入备注", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      updatecshopmallKocNotes({
            id: this.approveRow.id,
            isExamine: 1,
            integral: this.integral,
            remarks:this.remarks
          }).then((res) => {
            this.approveDialog.visible=false
            this.querycshopmallKocNotesPage();
          });
    },
    addsubmitThemeForm(){
      if(this.addParmas.activityId==''){
        this.$alert("请选择活动", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if(this.addParmas.userId==''){
        this.$alert("请输入会员ID", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if(this.addParmas.redBookName==''){
        this.$alert("请输入平台昵称", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if(this.addParmas.redBookId==''){
        this.$alert("请输入平台ID", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if(this.addParmas.wechatId==''){
        this.$alert("请输入微信号", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if(this.addParmas.referrer==''){
        this.$alert("请输入推荐人相关信息", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if(this.addParmas.noteLink==''){
        this.$alert("请输入作品链接", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if(this.addParmas.noteCollectionImage.length==0){
        this.$alert("请上传截图", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }

      this.addParmas.noteCollectionImage=this.addParmas.noteCollectionImage.join(',')
      addcshopmallKocNotes(this.addParmas).then((res) => {
            this.addDialog.visible=false
            this.querycshopmallKocNotesPage();
          });


    },
    unapproveClick(row){
      this.$prompt("驳回"+ row.redBookName + "这条数据,请填写驳回原因！", "驳回", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputPattern: /^.+$/,

        inputErrorMessage: "请填写驳回原因！",
      })
        .then(({ value }) => {
    
                 updatecshopmallKocNotes({
            id: row.id,
            isExamine: 2,
            remarks:value

          }).then((res) => {
            this.querycshopmallKocNotesPage();
          });
        })
        .catch((value) => {
          console.log("关闭" + value);
        });


  
    },
    delteClicks(row){
      this.$confirm("删除" + row.redBookName + "这条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delcshopmallKocNotes({
            id: row.id,
          }).then((res) => {
            this.querycshopmallKocNotesPage();
          });
      });
  
    },

    //取消审核
    failedClick(row) {
      this.$confirm(
        "扣除上次添加的" + row.integral + "积分, 是否继续取消审核?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        updatecshopmallKocNotes({
            id: row.id,
            isExamine: 3,
            integral: row.integral,
          }).then((res) => {
            this.querycshopmallKocNotesPage();
          });
      });
    },
    add(){
        this.addParmas.activityId=''
        this.addParmas.noteCollectionImage=[]
        this.addParmas.noteLink=''
        this.addParmas.redBookId=''
        this.addParmas.redBookName=''
        this.addParmas.referrer=''
        this.addParmas.userId=''
        this.addParmas.type=''
        this.addParmas.wechatId=''
      this.addDialog.visible=true
    },
    bannerhandleSuccess(res) {
    
      this.addParmas.noteCollectionImage.push(res.data.rootPath);
    },
    bannerhandleRemove(file, fileList) {
      let arr=[]
      fileList.map((item, index) => {
   
          arr.push(item.response.data.rootPath)
        
      });
      this.addParmas.noteCollectionImage=arr
      console.log(this.addParmas.noteCollectionImage)
    },
    bannerhandlePictureCardPreview(file) {
      this.bannerdialogImageUrl = file.response.data.rootPath;
      this.bannerdialogVisible = true;
    },


 
  },
};
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.red {
  color: red;
}
.turnBule {
  color: #333;
  background-color: #58e3e8;
  border-color: rgba(56, 185, 190, 0.12);
  margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
  background: rgba(56, 185, 190, 0.5);
  border-color: rgba(56, 185, 190, 0.5);
  color: #fff;
}

.el-button--text.reviewed {
  color: #38b9be;
}

.reviewed {
  color: #38b9be;
}

.el-tree-node__content {
  z-index: 999;
  padding-left: 40px;
}

.directory-in {
  width: 32%;
  line-height: 60px;
  background: #4095e5;
}
.hide_box {
  ::v-deep.el-upload--picture-card {
    display: none;
  }
}
</style>
